import { AnimationEvent, animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicPopupMode, Role } from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { AppState, selecCompanyInCommonState, selectUserInCommonState } from '../../../app-state.index';
import { AppAuthService } from '../../../kesher-shared/services/app-auth.service';
import { CompanyComponent } from '../company/company.component';
import { DefaultLayoutComponent } from '../default-layout/default-layout.component';
import { TopbarService } from './topbar.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' })),
            ]),
            transition(':leave', [
                animate('.1s linear', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class AppTopBarComponent {

    company$: Observable<any>;
    userInfo$: Observable<any>;
    lastEntranceDate: Date;
    activeItem: number;
    logoRouterLink: string;
    companyNameRouterLink:string;
    displayChangeCompany = false;
    adminRole = Role.KesherUser;
    isMobile: boolean = window.innerWidth > 991 ? false : true;
    constructor(public defaultLayout: DefaultLayoutComponent,
        private store: Store<AppState>,
        public authService: AppAuthService,
        public topbarService: TopbarService,
        private _cdr: ChangeDetectorRef,

    ) {
        this.store.select(selecCompanyInCommonState).subscribe((company: any) => {
            if (company) {
                this.logoRouterLink = "/kesher/" + company.companyCode + "/home";
                this.companyNameRouterLink= "/kesher/" + company.companyCode + "/company-settings";
            }
            else{
                this.logoRouterLink = "/kesher/home";
                this.companyNameRouterLink = "/kesher/company-settings";
            }
        });
    }
    userInfo:any;
    @ViewChild('companies') companies: CompanyComponent;
    @ViewChild('searchInput') searchInputViewChild: ElementRef;
    displayUserEditModal: boolean = false;
    accept(){}
    onSearchAnimationEnd(event: AnimationEvent) {
        switch (event.toState) {
            case 'visible':
                this.searchInputViewChild.nativeElement.focus();
                break;
        }
    }
    aaa(){
        
    }
    openUserDetails(){
       
        this.userInfo = this.authService.getUserInfo().subscribe(res => {
            this.userInfo = res;
           this.displayUserEditModal=true;
          });
    }
    
    onCloseUserEditModal(event){ 
        this.displayUserEditModal=false;
        this._cdr.markForCheck();
       
       
    }
    ngOnInit() {
        this.userInfo$ = this.store.select(selectUserInCommonState);
        this.company$ = this.store.select(selecCompanyInCommonState);
        this.lastEntranceDate = this.authService.lastEntranceDate
    }

    onCloseCompanies() {
        this.displayChangeCompany = false;
    }
    logoFirstLetterCompanyName(companyName) {
        if (companyName == null)
            return "+"
        let words = companyName.split(" ");
        if (words.length > 1)
            return words[0].charAt(0) + words[1].charAt(0)
        return companyName.slice(0, 2)
    }

    openFastPayment() {
        this.topbarService.emitFastPaymentClickedEvent();
    }
    //#region KESHER PRODUCTS
    popupModes = DynamicPopupMode;
    companyWithoutChargeOptionModal = false;
    createChargeOptionModal = false;
    customerId: number;
    createChargeOption() {
        this.createChargeOptionModal = true;
        this.closeModalConfirm();
    }
    closeModalConfirm() {
        this.companyWithoutChargeOptionModal = false;
    }
    openKesherProducts() {
        this.topbarService.emitOpenKesherProductsClickedEvent();
    }

    openCloseMenu(event){
        this.topbarService.openCloseMenu.emit();
        this.defaultLayout.onMenuButtonClick(event);
    }
    companySettings() {
     this.companyNameRouterLink =  this.companyNameRouterLink;
    }
    //#endregion
}
