<div class="layout-wrapper  layout-menu-static 
layout-rtl layout-menu-light layout-topbar-blue" [ngClass]="{
'layout-menu-active': menuActive,
'layout-menu-mobile-active': mobileMenuActive,
'layout-topbar-mobile-active': mobileTopbarActive}">

    <app-topbar></app-topbar>
    <side-menu class="sideMenu" (activeItem)="activeItem($event)" (click)="onSideMenuClick($event)"></side-menu>
    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-menu [activeMenuItem]="activeMenuItem"></app-menu>
            
        </div>
    </div>
    

    <div class="layout-main">
        <div class="progressContainer">
            <p-progressBar mode="indeterminate" *ngIf="isLoading$ | async"></p-progressBar>
        </div>
        <div class="layout-content">
            <div class="card p-0 sm:p-3">
                <router-outlet></router-outlet>
            </div>
        </div>
        <contact-us class="fixed left-0 bottom-0 m-4 hidden md:flex"></contact-us>
    </div>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>
</div>

<!------ SIDE BER ------>
<p-sidebar (onHide)="removeSideBar()" [(visible)]="visibleSidebar" position="right" [baseZIndex]="10000"
     [styleClass]="sidebarStyleClass">
    <ng-container [ngTemplateOutlet]="contentSideBar" *ngIf="visibleSidebar"></ng-container>
</p-sidebar>